p.dotted {border-style: dotted;height:2rem}
  body {
    overflow-y: auto;
    margin: 0;
  }
  
  .allCat:hover{
      background-color: black;
      color:white;
  }

  .stickyHeader{
    position: sticky; top: 0;
    z-index: 99999;
    background-color: white;
  }

  .hero-3:before {
    background-image: url("../../../assets/footer_banner.jpg");
    background-attachment: fixed;
    z-index: -3;
    opacity: 0.5;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    content: "";
  }

  
  @media screen and (-webkit-min-device-pixel-ratio: 2) {
      #section-5:before {
          background-attachment: scroll;
      }
  }

@font-face {
  font-family: "ITC Caslon_224 W01 Book1551458";
  src: url("//cdn-media.amplience.com/liberty/fonts/ITCCaslon_224W01Book1551458.eot?#iefix");
  src: url("//cdn-media.amplience.com/liberty/fonts/ITCCaslon_224W01Book1551458.eot?#iefix") format("eot"), url("//cdn-media.amplience.com/liberty/fonts/ITCCaslon_224W01Book1551458.woff2") format("woff2"), url("//cdn-media.amplience.com/liberty/fonts/ITCCaslon_224W01Book1551458.woff") format("woff"), url("//cdn-media.amplience.com/liberty/fonts/ITCCaslon_224W01Book1551458.ttf") format("truetype"), url("//cdn-media.amplience.com/liberty/fonts/ITCCaslon_224W01Book1551458.svg#ITCCaslon_224W01Book1551458") format("svg");
}
@font-face {
  font-family: "ITC Caslon_224 W01 Book Italic";
  src: url("//cdn-media.amplience.com/liberty/fonts/ITCCaslon_224W01BookItalic.eot?#iefix");
  src: url("//cdn-media.amplience.com/liberty/fonts/ITCCaslon_224W01BookItalic.eot?#iefix") format("eot"), url("//cdn-media.amplience.com/liberty/fonts/ITCCaslon_224W01BookItalic.woff2") format("woff2"), url("//cdn-media.amplience.com/liberty/fonts/ITCCaslon_224W01BookItalic.woff") format("woff"), url("//cdn-media.amplience.com/liberty/fonts/ITCCaslon_224W01BookItalic.ttf") format("truetype"), url("//cdn-media.amplience.com/liberty/fonts/ITCCaslon_224W01BookItalic.svg#ITCCaslon_224W01BookItalic") format("svg");
}
@font-face {
  font-family: "ITC Caslon_224 W01 Bold1551474";
  src: url("//cdn-media.amplience.com/liberty/fonts/ITCCaslon_224W01Bold1551474.eot?#iefix");
  src: url("//cdn-media.amplience.com/liberty/fonts/ITCCaslon_224W01Bold1551474.eot?#iefix") format("eot"), url("//cdn-media.amplience.com/liberty/fonts/ITCCaslon_224W01Bold1551474.woff2") format("woff2"), url("//cdn-media.amplience.com/liberty/fonts/ITCCaslon_224W01Bold1551474.woff") format("woff"), url("//cdn-media.amplience.com/liberty/fonts/ITCCaslon_224W01Bold1551474.ttf") format("truetype"), url("//cdn-media.amplience.com/liberty/fonts/ITCCaslon_224W01Bold1551474.svg#ITCCaslon_224W01Bold1551474") format("svg");
}
@font-face {
  font-family: "ITC Caslon_224 W01 Bold Italic";
  src: url("//cdn-media.amplience.com/liberty/fonts/ITCCaslon_224W01BoldItalic.eot?#iefix");
  src: url("//cdn-media.amplience.com/liberty/fonts/ITCCaslon_224W01BoldItalic.eot?#iefix") format("eot"), url("//cdn-media.amplience.com/liberty/fonts/ITCCaslon_224W01BoldItalic.woff2") format("woff2"), url("//cdn-media.amplience.com/liberty/fonts/ITCCaslon_224W01BoldItalic.woff") format("woff"), url("//cdn-media.amplience.com/liberty/fonts/ITCCaslon_224W01BoldItalic.ttf") format("truetype"), url("//cdn-media.amplience.com/liberty/fonts/ITCCaslon_224W01BoldItalic.svg#ITCCaslon_224W01BoldItalic") format("svg");
}
@font-face {
  font-family: "Avenir LT W01_45 Book1475508";
  src: url("//cdn-media.amplience.com/liberty/fonts/AvenirLTW01_45Book1475508.eot?#iefix");
  src: url("//cdn-media.amplience.com/liberty/fonts/AvenirLTW01_45Book1475508.eot?#iefix") format("eot"), url("//cdn-media.amplience.com/liberty/fonts/AvenirLTW01_45Book1475508.woff2") format("woff2"), url("//cdn-media.amplience.com/liberty/fonts/AvenirLTW01_45Book1475508.woff") format("woff"), url("//cdn-media.amplience.com/liberty/fonts/AvenirLTW01_45Book1475508.ttf") format("truetype"), url("//cdn-media.amplience.com/liberty/fonts/AvenirLTW01_45Book1475508.svg#AvenirLTW01_45Book1475508") format("svg");
}
@font-face {
  font-family: "Avenir LT W01_45 Book O1475514";
  src: url("//cdn-media.amplience.com/liberty/fonts/AvenirLTW01_45BookO1475514.eot?#iefix");
  src: url("//cdn-media.amplience.com/liberty/fonts/AvenirLTW01_45BookO1475514.eot?#iefix") format("eot"), url("//cdn-media.amplience.com/liberty/fonts/AvenirLTW01_45BookO1475514.woff2") format("woff2"), url("//cdn-media.amplience.com/liberty/fonts/AvenirLTW01_45BookO1475514.woff") format("woff"), url("//cdn-media.amplience.com/liberty/fonts/AvenirLTW01_45BookO1475514.ttf") format("truetype"), url("//cdn-media.amplience.com/liberty/fonts/AvenirLTW01_45BookO1475514.svg#AvenirLTW01_45BookO1475514") format("svg");
}
@font-face {
  font-family: "Avenir LT W01_65 Medium1475532";
  src: url("//cdn-media.amplience.com/liberty/fonts/AvenirLTW01_65Medium1475532.eot?#iefix");
  src: url("//cdn-media.amplience.com/liberty/fonts/AvenirLTW01_65Medium1475532.eot?#iefix") format("eot"), url("//cdn-media.amplience.com/liberty/fonts/AvenirLTW01_65Medium1475532.woff2") format("woff2"), url("//cdn-media.amplience.com/liberty/fonts/AvenirLTW01_65Medium1475532.woff") format("woff"), url("//cdn-media.amplience.com/liberty/fonts/AvenirLTW01_65Medium1475532.ttf") format("truetype"), url("//cdn-media.amplience.com/liberty/fonts/AvenirLTW01_65Medium1475532.svg#AvenirLTW01_65Medium1475532") format("svg");
}
@font-face {
  font-family: "Avenir LT W01_65 Medium1475538";
  src: url("//cdn-media.amplience.com/liberty/fonts/AvenirLTW01_65Medium1475538.eot?#iefix");
  src: url("//cdn-media.amplience.com/liberty/fonts/AvenirLTW01_65Medium1475538.eot?#iefix") format("eot"), url("//cdn-media.amplience.com/liberty/fonts/AvenirLTW01_65Medium1475538.woff2") format("woff2"), url("//cdn-media.amplience.com/liberty/fonts/AvenirLTW01_65Medium1475538.woff") format("woff"), url("//cdn-media.amplience.com/liberty/fonts/AvenirLTW01_65Medium1475538.ttf") format("truetype"), url("//cdn-media.amplience.com/liberty/fonts/AvenirLTW01_65Medium1475538.svg#AvenirLTW01_65Medium1475538") format("svg");
}
@font-face {
  font-family: "Avenir LT W01_85 Heavy1475544";
  src: url("//cdn-media.amplience.com/liberty/fonts/AvenirLTW01_85Heavy1475544.eot?#iefix");
  src: url("//cdn-media.amplience.com/liberty/fonts/AvenirLTW01_85Heavy1475544.eot?#iefix") format("eot"), url("//cdn-media.amplience.com/liberty/fonts/AvenirLTW01_85Heavy1475544.woff2") format("woff2"), url("//cdn-media.amplience.com/liberty/fonts/AvenirLTW01_85Heavy1475544.woff") format("woff"), url("//cdn-media.amplience.com/liberty/fonts/AvenirLTW01_85Heavy1475544.ttf") format("truetype"), url("//cdn-media.amplience.com/liberty/fonts/AvenirLTW01_85Heavy1475544.svg#AvenirLTW01_85Heavy1475544") format("svg");
}
@font-face {
  font-family: "Avenir LT W01_85 Heavy_1475550";
  src: url("//cdn-media.amplience.com/liberty/fonts/AvenirLTW01_85Heavy_1475550.eot?#iefix");
  src: url("//cdn-media.amplience.com/liberty/fonts/AvenirLTW01_85Heavy_1475550.eot?#iefix") format("eot"), url("//cdn-media.amplience.com/liberty/fonts/AvenirLTW01_85Heavy_1475550.woff2") format("woff2"), url("//cdn-media.amplience.com/liberty/fonts/AvenirLTW01_85Heavy_1475550.woff") format("woff"), url("//cdn-media.amplience.com/liberty/fonts/AvenirLTW01_85Heavy_1475550.ttf") format("truetype"), url("//cdn-media.amplience.com/liberty/fonts/AvenirLTW01_85Heavy_1475550.svg#AvenirLTW01_85Heavy_1475550") format("svg");
}

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&family=Raleway+Dots&family=Syne+Tactile&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&family=Raleway+Dots&family=Shizuru&family=Syne+Tactile&display=swap');

*, *::before, *::after {
  box-sizing: border-box;
}

:root {
  --bgWhite: #ffffff;
  --bgBlack: #000000;
  --primaryColor: #660099;
  --colorGray: #eceaea;
  --colorMain: #F7B633;
  --brShadow: -6px 6px 15px rgba(0,0,0,0.5);
  --tlShadow: 6px -6px 15px rgba(255,255,255,0.8);
}

body {
  margin: 0;
  background-color: 'lightgray';
  font-family: 'sans-serif';
  /* height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--colorMain);
  margin-left: 100px; */
}

.text {
  font-size: 3.5em;
  font-weight: bold;
  text-transform: uppercase;
  text-shadow: 1px 1px 'lightgray';
  position: relative;
  width: 100%;
  -webkit-animation-name:fadeInLeft; 
  animation-name:fadeInLeft;
}

.title1{
  font-weight: 400;
  text-shadow: 1px 1px 'lightgray';
  position: relative;
  text-transform: capitalize;
}

.title1{
  letter-spacing: 1px;
  margin-left: 8px;
}


.dukan-name{
  font-weight: bold;
  font-size: 2.5em;
  font-family: 'Raleway', sans-serif;
  text-transform: uppercase;
  letter-spacing: 15;
  text-shadow: 1px 1px 'lightgray';
  position: relative;
}


.text::before {
  left: 0;
  width: 100px;
  height: 200px;
  top: -150px;
  z-index: 10;
  box-shadow: initial var(--brShadow), initial var(--tlShadow);
}
.text::after {
  left: -50px;
  width: 200px;
  height: 200px;  
  top: -200px;
  box-shadow: var(--brShadow), var(--tlShadow);
}

.header-bg{margin-top: 90 !important;}


input:focus {
  outline: none;
}

.visually-hidden {
  border: 0;
  height: 0.0625rem;
  margin: -0.0625rem;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 0.0625rem;
}

.top-row {
  background-color: var(--colorMain);
  height: 100px;
  font-size: 14px;
  font-family: "Avenir LT W01_85 Heavy1475544", sans-serif;
  letter-spacing: 1px;
  width: 100%;
  position: relative;
  text-align: center;
  border-bottom: 0.5px solid var(--colorMain);
}


@-webkit-keyframes pulse {
  0% {
    transform: scale(0.5);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.5);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
@-webkit-keyframes pulsecolor {
  10% {
    background: #dd0000;
  }
}
@keyframes pulsecolor {
  10% {
    background: #dd0000;
  }
}
.mini-cart-link {
  position: absolute;
  width: 80px;
  height: 80px;
  top: 0;
  right: 0;
}
.mini-cart-link svg {
  width: 30px;
  margin-top: 35px;
  z-index: 2;
  position: relative;
  color: white;
}
.mini-cart-link div {
  display: block;
  position: absolute;
  top: 12px;
  right: 14px;
  width: 22px;
  height: 22px;
}

.mini-cart-link div span {
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  text-decoration: none;
  color: #F7B633;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
}
.mini-cart-link div:before {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #ffffff;
  border-radius: 50%;
  z-index: 1;
  transition: all 300ms ease;
}


fieldset {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: 0 0;
}

.header-search {
  position: absolute;
  top: 0;
  left: 10px;
}

.header-search input[type=search] {
  -webkit-appearance: none;
  padding: 0 3.125rem 0 1.5625rem;
  height: 44px;
  border: 0;
  font-family: "ITC Caslon_224 W01 Book Italic", Georgia, serif;
  font-size: 1rem;
  width: 450px;
  background-color: 'white';
  background-image: none;
  border-radius: 3px;
  margin-top: 27px;
  text-align: left;
  transition: all 0.2s ease-in-out;
  color: #F7B633;
  text-shadow: 0 0 0 #333;
  -webkit-text-fill-color: #fff;
}

.st0{fill:#2d043b;}


.text span{

  position: relative;
  top: 30px;
  
}

@media (max-width: 768px) {
  .header-search input[type=search] {
    
    width: 250px;
    
  }

  .text {

    font-size: 2.5em;
    text-align: center;
    height: 200px;
    line-height: 1;


  }

  .top-row {
    height: 80px;
  }

  .header-search input[type=search] {

    margin-top: 12px;
  }

  .header-search .button-search svg {
    margin-top: 0px !important;
  }

  .mini-cart-link svg {
    margin-top: 20px;
  }

  .mini-cart-link div {
    top: 5px;
    width: 21px;
  }



  .text span{

    position: relative;
    top: 50px;
    
  }

  .header-search, .mini-cart-link{
    top: 7px;
  }

  .text span{margin-left: 0px;}

}


.header-search input[type=search]:focus {
  background-color: #dbdadb;
  -webkit-text-fill-color:#2d043b;
}
.header-search input[type=search]::-webkit-input-placeholder {
  color:#2d043b;
  /* change [placeholder color] by this*/
  text-shadow: none;
  -webkit-text-fill-color:#2d043b;
}
.header-search .button-search {
  position: absolute;
  right: 0;
  top: 0;
  height: 3.125rem;
  width: 3.125rem;
  outline: 0;
  border: 0;
  background-color: transparent;
  background-position: center;
  transition: all 0.2s ease-in-out;
}
.header-search .button-search svg {
  width: 24px;
  margin-top: 30px;
}

.demo {
  margin-top: 200px;
  padding: 20px;
  font-family: "Avenir LT W01_65 Medium1475532", sans-serif;
}
.demo h2 {
  font-size: 14px;
  font-weight: normal;
}
.demo p {
  font-size: 12px;
}
.demo button.add-to-cart {
  background-color: #7a1e99;
  color: #fff;
  border: 0;
  outline: 0;
  width: 200px;
  height: 40px;
  font-family: "Avenir LT W01_85 Heavy1475544", sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
  padding: 0 15px;
  float: left;
  margin: 0 4% 10px 0;
  border-radius: 0.125rem;
  cursor: pointer;
  transition: all 0.3s ease;
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
}



.row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around;
}

.col-2 {
  flex-basis: 50%;
  min-width: 300px;
}

.col-2 img {
  max-width: 100%;
  padding: 50px 0;
}

.col-2 h1 {
  font-size: 50px;
  line-height: 60px;
  margin: 25px 0;
}

.btn {
  display: inline-block;
  background: #ff523b;
  color: #ffffff;
  padding: 8px 30px;
  margin: 30px 0;
  border-radius: 30px;
  transition: background 0.5s;
}

.btn:hover {
  background: #563434;
}

.header {
  background: radial-gradient(#fff, #ffd6d6);
}

.header .row {
  margin-top: 70px;
}

.categories {
  margin: 70px 0;
}

.col-3 {
  flex-basis: 30%;
  min-width: 250px;
  margin-bottom: 30px;
}

.col-3 img {
  width: 100%;
}

.small-container {
  max-width: 1080px;
  margin: auto;
  padding-left: 25px;
  padding-right: 25px;
}

.col-4 {
  flex-basis: 25%;
  padding: 10px;
  min-width: 200px;
  margin-bottom: 50px;
  transition: transform 0.5s;
}

.col-4 img {
  width: 100%;
}

.title {
  text-align: center;
  margin: 0 auto 80px;
  position: relative;
  line-height: 60px;
  color: #555;
}
.title::after {
  content: "";
  background: #ff523b;
  width: 80px;
  height: 5px;
  border-radius: 5px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
}

h4 {
  color: #555;
  font-weight: normal;
}

.col-4 p {
  font-size: 14px;
}

.rating .fas {
  color: #ff523b;
}

.rating .far {
  color: #ff523b;
}

.col-4:hover {
  transform: translateY(-5px);
}

/* Offer */

.offer {
  background: radial-gradient(#fff, #ffd6d6);
  margin-top: 80px;
  padding: 30px 0;
}

.col-2 .offer-img {
  padding: 50px;
}

small {
  color: #555;
}

/* testimonial */

.testimonial {
  padding-top: 100px;
}

.testimonial .col-3 {
  text-align: center;
  padding: 40px 20px;
  box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.5s;
}

.testimonial .col-3 img {
  width: 100px;
  margin-top: 20px;
  border-radius: 50%;
}

.testimonial .col-3:hover {
  transform: translateY(-10px);
}

.fa-quote-left {
  font-size: 34px;
  color: #ff523b;
}

.col-3 p {
  font-size: 14px;
  margin: 12px 0;
  color: #777777;
}

.testimonial .col-3 h3 {
  font-weight: 600;
  color: #555;
  font-size: 16px;
}

.brands {
  margin: 100px auto;
}

.col-5 {
  width: 160px;
}

.col-5 img {
  width: 100%;
  cursor: pointer;
  filter: grayscale(100%);
}

.col-5 img:hover {
  width: 100%;
  cursor: pointer;
  filter: grayscale(0);
}

/* footer */

.footer {
  color: #8a8a8a;
  font-size: 14px;
  padding: 60px 0 20px;
  border: 'none';
}

.footer p {
  color: #8a8a8a;
}

.footer h3 {
  color: #ffffff;
  margin-bottom: 20px;
}

.footer-col-1,
.footer-col-2,
.footer-col-3,
.footer-col-4 {
  width: 250px;
  margin-bottom: 20px;
}

.footer-col-1 {
  flex-basis: 30%;
}

.footer-col-2 {
  flex: 1;
  text-align: center;
}

.footer-col-2 img {
  width: 180px;
  margin-bottom: 20px;
}

.footer-col-3,
.footer-col-4 {
  flex-basis: 12%;
  text-align: center;
}

ul {
  list-style-type: none;
}

.app-logo {
  margin-top: 20px;
}
.app-logo img {
  width: 140px;
}

.footer hr {
  border: none;
  background: #b5b5b5;
  height: 1px;
  margin: 20px 0;
}

.copyright {
  text-align: center;
}



/* media query for less than 600 screen size */

@media only screen and (max-width: 600px) {
  .row {
    text-align: center;
  }


  .footer .col-1,
  .footer .col-2,
  .footer .col-3,
  .footer .col-4 {
    flex-basis: 100%;
  }

  .col-2,
  .col-3,
  .col-4 {
    flex-basis: 100%;
  }

 
}

.product-title{
  font-family: 'Raleway', sans-serif;
  margin: 0.25em 0;
}

.page-section {
  position: relative;
  padding-top: 80px;
  padding-bottom: 80px;
  color: #898798;
  overflow: hidden;
}



/* #Carousel */

.carrousel h1 {
  font-size: 1.5em;
  text-align: center;
  margin: 1.2em 0;
  color: #555555;
}


/*Carousel*/
.carrousel {

  text-align: center;
  width: "100%";
  margin: 0px;
  padding: 0px;
  position: relative;
  overflow: hidden;
}
.carrousel h2 {
  margin: 0;
  margin-top: -1.7em;
  padding: 0;
  font-size: 1em;
  text-align: center;
  color: #bbbbbb;
}
.carrousel .slides {
  width: 400%;
  left: 0;
  padding-left: 0;
  overflow: hidden;
  list-style: none;
  position: relative;

  -webkit-transition: transform .5s;
  -moz-transition: transform .5s;
  -o-transition: transform .5s;
  transition: transform .5s;
}
.carrousel .slides li {
  width: 25%;
  position: relative;
  float: left;
}
.carrousel li p{
  margin-top: 0;  
}
.carrousel li q {
  max-width: 90%;
  margin: auto;
  color: #666666;
  font-size: 1.3em;
  font-weight: bold;
}
.carrousel li img {
  width: 3em;
  height: 3em;
  object-fit: cover;
  border-radius: 50%;
  margin-left: -1.5em;
  margin-right: 0.5em;
  vertical-align: middle;
}
.carrousel li span.author {
  margin-top:0.5em;
  font-size: 1.2em;
  color: #777777;
  display: block;
}
.carrousel .slidesNavigation {
  display: block;
  list-style: none;
  text-align: center;
  bottom: 1em;

 
}
.carrousel input {
  display: none;
}
.carrousel .slidesNavigation label {


  display: block;
  height: 10px;
  width: 10px;

  border: solid 2px #1f1f1f;
  font-size: 0;
}
/* You have to repeat this with each slide
TODO: make it easier with SCSS
25% movement 100/slides-num
*/
#radio-1:checked ~ .slides {
  transform: translateX(0%);
}

#radio-2:checked ~ .slides {
  transform: translateX(-25%);
}
#radio-3:checked ~ .slides {
  transform: translateX(-50%);
}
#radio-4:checked ~ .slides {
  transform: translateX(-75%);
}


.carrousel .slidesNavigation label:hover {
   cursor: pointer;
}
/* You have to repeat this with each slide/dot */
.carrousel #radio-1:checked ~ .slidesNavigation label#dotForRadio-1,
.carrousel #radio-2:checked ~ .slidesNavigation label#dotForRadio-2,
.carrousel #radio-3:checked ~ .slidesNavigation label#dotForRadio-3,
.carrousel #radio-4:checked ~ .slidesNavigation label#dotForRadio-4 {
  background: #ffffff;
}

@media  (max-width: 796px) {
  
}
@media  (max-width: 480px) {
  .carrousel li p {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
  .carrousel li q {
      font-size: 1em;
  }
  .carrousel li img {
     width:2em;
     margin-left: -1em;
     margin-right: 0.25em;
  }
}



/* #product card */


/* 
.card-1 {
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.card-1:hover {
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.card-2 {
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.card-3 {
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.card-4 {
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.card-5 {
  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
} */


.card-elevation{
 /*sombra*/
   -moz-box-shadow:                                                             1px 1px 5px 0px rgba(50, 50, 50, 0.25);
   -webkit-box-shadow:                                                          1px 1px 5px 0px rgba(50, 50, 50, 0.25);
   box-shadow:                                                                  1px 1px 5px 0px rgba(50, 50, 50, 0.25);;
}


.Item__location{
  color:rgba(50, 50, 50, 0.25),
}

.card {
  background: #fff;
  border-radius: 10px;
  /* margin-right: 10px; */
  transform: scale(0.95);
  transition: box-shadow 0.5s, transform 0.5s;
  
}


.card:hover{
    transform: scale(1);
    box-shadow: 5px 20px 30px rgba(0, 0, 0, 0.2);
}


.category {
  transform: scale(0.95);
  transition: 0.5s, transform 0.5s;
}

.category:hover{
    transform: scale(1);
}


.section-title {
  font-family: sans-serif;
  transform: scale(0.95);
  transition: box-shadow 0.5s, transform 0.5s;
}

.section-title:hover{
    transform: scale(1.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}


.navbar-menu {
  font-family: sans-serif;
  transform: scale(1);
  transition: box-shadow 0.5s, transform 0.5s;
}

.navbar-menu:hover{
    transform: scale(1.3);
}


/* .category:hover .content{
  border-width: '1px';
  border-color: var(--bgWhite),
} */



/* Search bar*/




.ReactModal__Overlay {
  opacity: 0;
  transform: translateX(500px);
  transition: all 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transform: translateX(0px);
}

.ReactModal__Overlay--before-close {
  opacity: 1;
  transform: translateX(500px);
  transition: all 500ms ease-in-out;
}


.line{
  content: "";
  display: inline-block;
  position: absolute;
  -webkit-clip-path: polygon(0 0, 60% 0, 36% 100%, 0 100%);
  clip-path: polygon(0 0, 15% 0, 96% 100%, 0 100%);
}



/* 
.footer-col-4 ul{margin-left: -10px;}
.footer-col-3 ul{margin-left: -10px;} */


hr.hr-1 {
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
}


.hh-grayBox {
	margin-bottom: 20px;
	padding: 35px;
  margin-top: 20px;
}
.pt45{padding-top:45px;}
.order-tracking{
	text-align: center;
	width: 33.33%;
	position: relative;
	display: block;
}
.order-tracking .is-complete{
	display: block;
	position: relative;
	border-radius: 50%;
	height: 30px;
	width: 30px;
	border: 0px solid #AFAFAF;
	background-color: #ffffff;
	margin: 0 auto;
	transition: background 0.25s linear;
	-webkit-transition: background 0.25s linear;
	z-index: 2;
}
.order-tracking .is-complete:after {
	display: block;
	position: absolute;
	content: '';
	height: 14px;
	width: 7px;
	top: -2px;
	bottom: 0;
	left: 5px;
	margin: auto 0;
	border: 0px solid #AFAFAF;
	border-width: 0px 2px 2px 0;
	transform: rotate(45deg);
	opacity: 0;
}
.order-tracking.completed .is-complete{
	border-color: #27aa80;
	border-width: 0px;
	background-color: #27aa80;
}
.order-tracking.completed .is-complete:after {
	border-color: #fff;
	border-width: 0px 3px 3px 0;
	width: 7px;
	left: 11px;
	opacity: 1;
}
.order-tracking p {
	color: #A4A4A4;
	font-size: 16px;
	margin-top: 8px;
	margin-bottom: 0;
	line-height: 20px;
}
.order-tracking p span{font-size: 14px;}
.order-tracking.completed p{color: rgb(255, 255, 255);}
.order-tracking::before {
	content: '';
	display: block;
	height: 3px;
	width: calc(100% - 40px);
	background-color: #ffffff;
	top: 13px;
	position: absolute;
	left: calc(-50% + 20px);
	z-index: 0;
}
.order-tracking:first-child:before{display: none;}
.order-tracking.completed:before{background-color: #27aa80;}


.nameAnimation {
  position: relative;
  font-family: sans-serif;
  text-transform: uppercase;
  font-size: 2em;
  letter-spacing: 4px;
  overflow: hidden;
  background: linear-gradient(90deg, #000, #fff, #000);
  background-repeat: no-repeat;
  background-size: 80%;
  animation: animate 3s linear infinite;
  background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
}

@keyframes animate {
  0% {
    background-position: -500%;
  }
  100% {
    background-position: 500%;
  }
}



.cat-header-1 h1 {
  text-align:center; font-size:50px; text-transform:uppercase; letter-spacing:1px;
  font-family:"Playfair Display", serif; font-weight:400;
}
.cat-header-1 h1 span {
    margin-top: 5px;
    font-size:15px; word-spacing:1px; font-weight:normal; letter-spacing:4px;
    text-transform: uppercase; font-family:"Raleway", sans-serif; font-weight:500;
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    grid-template-rows: 27px 0;
    grid-gap: 20px;
    align-items: center;

}

.cat-header-1 h1 span:after,.cat-header-1 h1 span:before {
    content: " ";
    display: block;
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc;
    height: 5px;
    background-color:#f8f8f8;
} 



h1, h4 {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: 50px;
  color: #ffffff;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}


h1 em {
  font-style: normal;
  font-weight: 600;
}

/* === HEADING STYLE #2 === */
.two h1 {
  text-transform: capitalize;
  margin-top: 20px;
}

.two h1 h4 {
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 4px;
  line-height: 3em;
  color: white;
}


.alt-two h1 {
  text-align:center;
}

@media (max-width: 820px) {
  
.footer-col-1,
.footer-col-2,
.footer-col-3,
.footer-col-4 {
  min-width: 100% !important;
  margin-bottom: 20px;
}


}

@keyframes ldio-q1sjvrz6fl-r {
  0%, 25%, 50%, 75%, 100% { animation-timing-function: cubic-bezier(0,1,0,1) }
  0% { transform: scale(0.7000000000000001) rotate(180deg) }
  25% { transform: scale(0.7000000000000001) rotate(270deg) }
  50% { transform: scale(0.7000000000000001) rotate(360deg) }
  75% { transform: scale(0.7000000000000001) rotate(450deg) }
  100% { transform: scale(0.7000000000000001) rotate(540deg) }
}
@keyframes ldio-q1sjvrz6fl-z {
  0%, 50%, 100% { animation-timing-function: cubic-bezier(1,0,0,1) }
  0% { transform: scale(1) }
  50% { transform: scale(1) }
  100% { transform: scale(.5) }
}
@keyframes ldio-q1sjvrz6fl-p {
  0%, 50%, 100% { animation-timing-function: cubic-bezier(1,0,0,1) }
  0% { transform: scale(0) }
  50% { transform: scale(1) }
  100% { transform: scale(1) }
}
@keyframes ldio-q1sjvrz6fl-c {
  0%, 25%, 50%, 75%, 100% { animation-timing-function: cubic-bezier(0,1,0,1) }
  0% { background: #1d0e0b }
  25% { background: #cfc6c2 }
  50% { background: #b1aaa5 }
  75% { background: #e6e3dc }
  100% { background: #1d0e0b }
}
.ldio-q1sjvrz6fl > div {
  animation: ldio-q1sjvrz6fl-r 4s linear infinite;
  transform-origin: 100px 100px;
}
.ldio-q1sjvrz6fl > div > div {
  width: 200px;
  height: 200px;
  position: absolute;
  animation: ldio-q1sjvrz6fl-z 1s linear infinite;
  transform-origin: 200px 200px;
}
.ldio-q1sjvrz6fl > div > div div {
  position: absolute;
  width: 100px;
  height: 100px;
  background: #1d0e0b;
  transform-origin: 50px 50px
}
.ldio-q1sjvrz6fl > div > div div:nth-child(1) {
  left: 0px;
  top: 0px;
  animation: ldio-q1sjvrz6fl-p 1s linear infinite, ldio-q1sjvrz6fl-c 4s linear infinite;
}
.ldio-q1sjvrz6fl > div > div div:nth-child(2) {
  left: 100px;
  top: 0px;
  animation: ldio-q1sjvrz6fl-p 1s linear infinite, ldio-q1sjvrz6fl-c 4s linear infinite;
}
.ldio-q1sjvrz6fl > div > div div:nth-child(3) {
  left: 0px;
  top: 100px;
  animation: ldio-q1sjvrz6fl-p 1s linear infinite, ldio-q1sjvrz6fl-c 4s linear infinite;
}
.ldio-q1sjvrz6fl > div > div div:nth-child(4) {
  left: 100px;
  top: 100px;
  transform: scale(1);
  animation: ldio-q1sjvrz6fl-c 4s linear infinite
}
.loadingio-spinner-chunk-nx0mjvmz269 {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: rgba(NaN, NaN, NaN, 0);
}
.ldio-q1sjvrz6fl {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-q1sjvrz6fl div { box-sizing: content-box; }
/* generated by https://loading.io/ */



/* Style 9
   ----------------------------- */
   .nine h1 {
    text-align:center; font-size:50px; text-transform:uppercase; color:#222; letter-spacing:1px;
    font-family:"Playfair Display", serif; font-weight:400;
  }
  .nine h1 span {
    margin-top: 5px;
      font-size:15px; color:#444; word-spacing:1px; font-weight:normal; letter-spacing:2px;
      text-transform: uppercase; font-family:"Raleway", sans-serif; font-weight:500;
  
      display: grid;
      grid-template-columns: 1fr max-content 1fr;
      grid-template-rows: 27px 0;
      grid-gap: 20px;
      align-items: center;
  }
  
  .nine h1 span:after,.nine h1 span:before {
      content: " ";
      display: block;
      border-bottom: 1px solid #ccc;
      border-top: 1px solid #ccc;
      height: 5px;
    background-color:#f8f8f8;
  }
/* === BASE HEADING === */ 

h1 {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  font-size: 40px;
  color: #080808;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

h1 span {
  display: block;
  font-size: 0.5em;
  line-height: 1.3;
}
h1 em {
  font-style: normal;
  font-weight: 600;
}

  /* === HEADING STYLE #2 === */
.two h1 {
  text-transform: capitalize;
}
.two h1:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 2px;
  content: "";
  background-color: var(--bg-color);
}

.two h1 span {
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 4px;
  line-height: 3em;
  padding-left: 0.25em;
  color: rgba(0, 0, 0, 0.4);
  padding-bottom: 10px;
}
.alt-two h1 {
  text-align:center;
}
.alt-two h1:before {
  left:50%; margin-left:-30px;
}

.ten h1 {
  font-size:25px; font-weight:500; 
  /* text-transform:uppercase; */
}
/* .ten h1:before {
    background-color: var(--bg-color);
    border-radius: 0.25rem;
    content: '';
    display: block;
    height: 0.25rem;
    width: 42px;
    margin-bottom: 0.60rem;
    
} */

.text-animate {
	position: relative;
}

.text-animate h2 {
	color: #fff;
	font-size: 8em;
	position: absolute;
	transform: translate(-50%, -50%);
}

.text-animate h2:nth-child(1) {
	color: transparent;
	-webkit-text-stroke: 2px #03a9f4;
}

.text-animate h2:nth-child(2) {
	color: #03a9f4;
	animation: animate 4s ease-in-out infinite;
}

@keyframes animate {
	0%,
	100% {
		clip-path: polygon(
			0% 45%,
			16% 44%,
			33% 50%,
			54% 60%,
			70% 61%,
			84% 59%,
			100% 52%,
			100% 100%,
			0% 100%
		);
	}

	50% {
		clip-path: polygon(
			0% 60%,
			15% 65%,
			34% 66%,
			51% 62%,
			67% 50%,
			84% 45%,
			100% 46%,
			100% 100%,
			0% 100%
		);
	}
}
.page_404{ padding:40px 0; background:#fff; font-family: 'Arvo', serif;
}

.page_404  img{ width:100%;}

.four_zero_four_bg{
 
 background-image: url(/src/assets/no_found.webp);
 background-repeat: no-repeat;
 height: 400px;
 background-position: center;
 }
 
 
 .four_zero_four_bg h1{
 font-size:80px;
 }
 
.four_zero_four_bg h3{
			 font-size:80px;
			 }
			 
.link_404{			 
	color: #fff!important;
    padding: 10px 20px;
    background: #000000;
    margin: 20px 0;
    text-decoration: none !important;
    display: inline-block;}

.link_404:hover{			 
        color: rgb(0, 0, 0)!important;
        padding: 10px 20px;
        border: 1px solid black;
        background: #ffffff;
        margin: 20px 0;
        transition: 1s;
        text-decoration: none !important;
        display: inline-block;}


.contant_box_404{ margin-top:-50px;}